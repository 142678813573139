<template>
  <div class="degreeSudyDetails">
    <template v-if="$route.name=='degreeSudyDetails'">
      <div class="search_sList">
        <div class="top_opera">
          <el-form
            :inline="true"
            :model="formInline"
            class="demo-form-inline"
            label-width="80px"
          >
            <el-form-item label="学校">
              <el-select
                v-model="formInline.tenantId"
                placeholder="请选择学校"
                @change="changeTen"
                filterable
                clearable
              >
                <el-option
                  v-for="item in allSchoolData"
                  :key="item.tenantId"
                  :label="item.tenantName"
                  :value="item.tenantId"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="姓名">
              <el-input
                v-model="formInline.name"
                placeholder="姓名"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="学生账号">
              <el-input
                v-model="formInline.loginNum"
                placeholder="学生账号"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="学号">
              <el-input
                v-model="formInline.studentNum"
                placeholder="学号"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="批次:" prop="admissionBatch">
              <el-select v-model="formInline.admissionBatch" placeholder="请选择" @focus="getGoSchoolData">
                <el-option
                  v-for="item in schoolBatchData"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="层次">
              <el-select v-model="formInline.eduCategory" placeholder="层次">
                <el-option v-for="(item, key, index) in $common.getEduCategory" :key="index" :label="item" :value="key"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="专业">
              <el-select v-model="formInline.majorId" placeholder="请选择" @focus="getMajorDataEvent" filterable clearable>
                <el-option
                  v-for="item in majorNameData"
                  :key="item.majorId"
                  :label="item.majorName"
                  :value="item.majorId">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="教学点">
              <el-select v-model="formInline.stationId" placeholder="教学点" filterable clearable @focus="getTheDropDownBoxData">
                <el-option
                  v-for="item in correspondenceStation"
                  :key="item.stationsId"
                  :label="item.stationsName"
                  :value="item.stationsId">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="开通状态">
              <el-select v-model="formInline.openState" placeholder="开通状态" filterable clearable>
                <el-option label="未开通" value="1"></el-option>
                <el-option label="已开通" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div class="btn_area">
            <el-button
              class="mainBtn"
              @click="onSubmit"
              plain
              v-preventReClick
              >搜索</el-button
            >
            <el-button type="info" @click="resetEvent" plain>重置</el-button>
          </div>
        </div>
      </div>
      <div class="table_sList">
        <el-table
          :data="tableData"
          stripe
          border
          style="width: 100%"
          align="center"
        >
          <el-table-column
            prop="tenantName"
            label="学校"
            align="center"
          >
            <template slot-scope="scope">
              <el-tooltip
                :content="scope.row.tenantName"
                placement="top"
                effect="light"
                popper-class="pubToop"
              >
                <div class="ellipsis">{{ scope.row.tenantName || '/' }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="studentName"
            label="姓名"
            align="center"
          >
            <template slot-scope="scope">
              <el-tooltip
                :content="scope.row.studentName"
                placement="top"
                effect="light"
                popper-class="pubToop"
              >
                <div class="ellipsis">{{ scope.row.studentName || '/' }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="loginNum"
            label="学生账号"
            align="center"
          >
            <template slot-scope="scope">
              <el-tooltip
                :content="scope.row.loginNum"
                placement="top"
                effect="light"
                popper-class="pubToop"
              >
                <div class="ellipsis">{{ scope.row.loginNum || '/' }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="studentNum"
            label="学号"
            align="center"
          >
            <template slot-scope="scope">
              <el-tooltip
                :content="scope.row.studentNum"
                placement="top"
                effect="light"
                popper-class="pubToop"
              >
                <div class="ellipsis">{{ scope.row.studentNum || '/' }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="className"
            label="所属班级"
            align="center"
          >
            <template slot-scope="scope">
              <el-tooltip
                :content="scope.row.className"
                placement="top"
                effect="light"
                popper-class="pubToop"
              >
                <div class="ellipsis">{{ scope.row.className || '/' }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="stationName"
            label="教学点"
            align="center"
          >
            <template slot-scope="scope">
              <el-tooltip
                :content="scope.row.stationName"
                placement="top"
                effect="light"
                popper-class="pubToop"
              >
                <div class="ellipsis">{{ scope.row.stationName || '/' }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="admissionBatch"
            label="批次"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="eduCategory"
            label="层次"
            align="center"
          >
            <template slot-scope="scope">
              {{$common.getEduCategory[scope.row.eduCategory]}}
            </template>
          </el-table-column>
          <el-table-column
            label="专业"
            align="center"
            width="80"
          >
            <template slot-scope="scope">
              <el-tooltip
                :content="scope.row.majorName"
                placement="top"
                effect="light"
                popper-class="pubToop"
              >
                <div class="ellipsis">{{ scope.row.majorName || '/' }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="openState"
            label="开通状态"
            align="center"
          >
            <template> 已开通 </template>
          </el-table-column>
          <el-table-column
            prop="openTime"
            label="开通时间"
            align="center"
            width="140"
          >
            <template slot-scope="scope">
              <span>{{timestampReturnDate(scope.row.openTime, 'datetime') || '/'}}</span>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            width="170"
            align="center">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="changeClass(scope.row)" v-if="$isShowModle('tch_foreign_class_stu_cutover')">切换班级</el-button>
              <el-button type="text" size="small" @click="operaEvent('jurisdiction', scope.row)" v-if="$isShowModle('tch_foreign_class_stu_close')">关闭权限</el-button>
              <!-- <el-button type="text" size="small" @click="jumpDetails(scope.row)">详情</el-button>
              <el-button type="text" size="small" @click="operaEvent('reset', scope.row)" v-if="scope.row.accountStatus == 1">重置密码</el-button>
              <el-button type="text" size="small" @click="operaEvent('close', scope.row)" v-if="scope.row.accountStatus == 1">关闭账号</el-button> -->
            </template>
          </el-table-column>
        </el-table>
        <Pagination
          @changePage="changePage"
          :childMsg="childMsg"
        ></Pagination>
      </div>
      <dialogDiy
        :isShow="isShowStudyOpera"
        :dialogDiyData="dialogStudyDiyData"
        @dialogEvent="isShowStudyOpera = false"
      >
        <div slot="dialogContent" class="dia_opera">
          <img class="errIcon" src="../../../assets/img/warn.png" alt="" />
          <p>{{title}}</p>
          <div class="btn_area">
            <el-button @click="isShowStudyOpera = false">关闭</el-button>
            <el-button class="mainBtn" @click="doEvent">确定</el-button>
          </div>
        </div>
      </dialogDiy>
      <dialogDiyNew :isShow="isShowChange" :dialogDiyData="dialogAddDiyData"
      @dialogEvent="isShowChange = false">
        <div slot="dialogContent" class="course_area">
          <div class="table_list_new">
            <el-table
              :data="tableClassData"
              stripe
              border
              style="width: 100%"
              align="center"
              height="400"
            >
              <el-table-column
                label=" "
                align="center"
                width="40"
              >
                <template slot-scope="scope">
                  <el-checkbox @change="checkCourse(scope.row)" v-model="scope.row.checked"></el-checkbox>
                </template>
              </el-table-column>
              <el-table-column
                prop="className"
                label="班级标题"
                align="center"
              >
                <!-- <template slot-scope="scope">
                  <el-tooltip
                    :content="scope.row.courseName"
                    placement="top"
                    effect="light"
                    popper-class="pubToop"
                  >
                    <div class="ellipsis">{{ scope.row.courseName || '/' }}</div>
                  </el-tooltip>
                </template> -->
              </el-table-column>
            </el-table>
            <!-- <Pagination
              @changePage="changeClassPage"
              :childMsg="childClassMsg"
            ></Pagination> -->
          </div>
          <div class="btn_area">
            <el-button @click="isShowChange = false">取消</el-button>
            <el-button type="primary" @click="enterClassEvent">确认</el-button>
          </div>
        </div>
      </dialogDiyNew>
    </template>
    <template v-else>
      <router-view></router-view>
    </template>
  </div>
</template>

<script>
import way from '../../../api/encapsulation'
import Pagination from '../../Pagination'
import dialogDiy from '../../commonModule/dialogDiy'
import dialogDiyNew from '../../commonModule/dialogDiyNew'
export default {
  name: 'degreeSudyDetails',
  components: {
    Pagination,
    dialogDiy,
    dialogDiyNew
  },
  data() {
    return {
      formInline: {
        admissionBatch: null,
        classId: null,
        eduCategory: null,
        loginNum: null,
        majorId: null,
        name: null,
        openState: null,
        page: 1,
        pageSize: 10,
        stationId: null,
        studentNum: null,
        tenantId: null
      },
      allSchoolData: [],
      schoolBatchData: [],
      majorNameData: [],
      correspondenceStation: [],
      tableData: [],
      childMsg: {
        current: 1,
        size: 10,
        total: 1,
      },
      title: null,
      isShowStudyOpera: false,
      dialogStudyDiyData: {
        title: null,
        sizeStyle: {width: '400px',height: '234px'}
      },
      rowData: null,
      rowType: null,
      isShowChange: false,
      dialogAddDiyData: {
        title: '切换班级',
        sizeStyle: {width: '760px',height: '600px'}
      },
      classForm: {
        name: null,
        pages: 1,
        size: 10,
        releaseStatus: null
      },
      tableClassData: [],
      childClassMsg: {
        current: 1,
        size: 10,
        total: 1
      },
      checkedRow: {}
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal.name == "degreeSudyDetails") {
          this.formInline.classId = this.$route.query.foreignClassId
          this.getTenantDown()
          this.queryClassStudent()
        }
      },
    },
  },
  methods: {
    timestampReturnDate: way.timestampReturnDate,
    // 搜索
    onSubmit() {
      this.formInline.page = 1;
      this.formInline.pageSize = 10;
      this.queryClassStudent();
    },
    // 重置
    resetEvent() {
      this.formInline.accountStatus = null
      this.formInline.admissionBatch = null
      this.formInline.cardId = null
      this.formInline.disputeFlag = null
      this.formInline.eduCategory = null
      this.formInline.majorId = null
      this.formInline.name = null
      this.formInline.page = 1
      this.formInline.pageSize = 10
      this.formInline.schoolId = null
      this.formInline.stationId = null
    },
    changeTen() {
      this.formInline.admissionBatch = null;
      this.schoolBatchData = [];
      this.getGoSchoolData();
      this.formInline.majorId = null;
      this.majorNameData = [];
      this.formInline.stationId = null;
      this.correspondenceStation = [];
      this.getTheDropDownBoxData();
    },
    // 获取学校数据
    getTenantDown() {
      this.$request.getTenantDownData({}, res => {
        if(res.code == 0) {
          this.allSchoolData = res.data;
        }
      })
    },
    // 获取入学批次数据
    getGoSchoolData() {
      if(!this.formInline.schoolId) {
        this.Warn('先选择学校');
        return;
      }
      if(this.schoolBatchData.length != 0) return;
      let obj = {
        tenantId: this.formInline.schoolId
      }
      this.$request.getGoSchool(obj, res => {
        if(res.code == 0) {
          this.schoolBatchData = res.data;
        }
      })
    },
    // 专业数据
    getMajorDataEvent() {
      if(!this.formInline.schoolId) {
        this.Warn('请选择学校');
        return;
      }
      if(!this.formInline.admissionBatch) {
        this.Warn('请选择批次');
        return;
      }
      if(!(typeof this.formInline.eduCategory == 'string' || typeof this.formInline.eduCategory == 'number')) {
        this.Warn('请选择层次')
        return;
      }
      if(this.majorNameData.length != 0) return;
      let obj = {
        tenantId: this.formInline.schoolId,
        eduCategory: this.formInline.eduCategory,
        batch: this.formInline.admissionBatch
      }
      this.$request.getMajorList(obj).then(res=>{
        if(res.data.code == 0) {
          this.majorNameData = res.data.data;
        }
      })
    },
    // 获取教学点数据
    getTheDropDownBoxData() {
      if(!this.formInline.schoolId) {
        this.Warn('请选择学校');
        return;
      }
      if(this.correspondenceStation.length != 0) return;
      let obj = {
        tenantId: this.formInline.schoolId
      }
      this.$request.getTheDropDownBoxs(obj, res => {
        if(res.code == 0){
          this.correspondenceStation = res.data;
        }
      })
    },
    // 获取班级学生数据
    queryClassStudent() {
      this.$request.classDegreeStudent(this.formInline).then(res => {
        if (res.data.code == 0) {
          let { records, current, size, total } = res.data.data;
          this.tableData = records;
          this.childMsg = {
            current,
            size,
            total
          };
        }
      });
    },
    changePage(val) {
      this.childMsg = val;
      this.formInline.page = val.current;
      this.formInline.pageSize = val.size;
      this.queryClassStudent();
    },
    showHideEvent() {
      this.isShowStudyOpera = !this.isShowStudyOpera;
    },
    operaEvent(type, row) {
      this.title = type == 'reset' ? '确定是否要重置密码？' : type == 'jurisdiction' ? '确定是否要关闭权限' : '确定是否要关闭账号？'
      this.rowData = row
      this.rowType = type
      this.showHideEvent()
    },
    doEvent() {
      if(this.rowType == 'reset') {
        this.$request.resetStuPass({username: this.rowData.loginNum}).then(res=>{
          if(res.data.code == 0) {
            this.Success(res.data.data);
            this.queryClassStudent();
            this.showHideEvent();
          }
        })
      } else if(this.rowType == 'jurisdiction') {
        let jurObj = {
          foreignId: this.$route.query.foreignClassId,
          studentId: this.rowData.studentId
        }
        this.$request.closeDegreeStuJurisdic(jurObj).then(res => {
          if(res.data.code == 0) {
            this.Success(res.data.msg)
            this.queryClassStudent();
            this.showHideEvent();
          }
        })
      } else {
        let obj = {
          aidId: this.rowData.id,
          tenantId: this.rowData.tenantId
        }
        this.$request.closeAccount(obj).then(res=>{
          if(res.data.code == 0) {
            this.Success(res.data.msg);
            this.queryClassStudent();
            this.showHideEvent();
          }
        })
      }
    },
    jumpDetails(row) {
      this.$router.push({name: 'studentStudyDetails', query:{
        tenantId: row.tenantId,
        aidStudentId: row.id,
        breadcrumb: '助学管理,班级管理,学习人数详情'
      }})
    },
    changeClass(row) {
      this.rowData = row
      this.isShowChange = !this.isShowChange
      this.getDegreeClassToPage()
    },
    changeClassPage() {
      // this.childClassMsg = val;
      // this.classForm.pages = val.current;
      // this.classForm.size = val.size;
      // this.queryClassStudent();
    },
    // 获取班级表格数据
    getDegreeClassToPage() {
      this.$request.degreeStuClassList().then(res => {
        if (res.data.code == 0) {
          // let { records, current, size, total } = res.data.data;
          res.data.data.forEach(item=>item.checked = false)
          this.tableClassData = res.data.data;
          // this.childClassMsg = {
          //   current,
          //   size,
          //   total
          // };
          this.checkCourse()
        }
      });
    },
    checkCourse(row) {
      if(row) this.checkedRow = row
      this.tableClassData.forEach(item => {
        item.checked = false
        if(item.classId == this.checkedRow.classId) item.checked = true
      })
    },
    enterClassEvent() {
      if(!this.checkedRow.classId) {
        this.Warn('请选择班级');
        return;
      }
      let obj = {
        foreignId: this.checkedRow.classId,
        studentId: this.rowData.studentId
      }
      this.$request.changeDegreeStuClass(obj).then(res => {
        if(res.data.code == 0) {
          this.Success(res.data.msg || '操作成功')
          this.isShowChange = !this.isShowChange
          this.checkedRow = {}
          this.queryClassStudent()
        }
      })
    }
  },
}
</script>

<style lang='less'>
  .degreeSudyDetails {
    height: 100%;
    .table_list_new {
      margin-bottom: 20px;
      padding: 0;
      .el-pagination {
        text-align: right;
      }
    }
  }
</style>